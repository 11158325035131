// app/javascript/controllers/select_controller.js

import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import { get } from '@rails/request.js'

export default class extends Controller {
  // Define static value to fetch the URL from HTML data attributes.
  static values = {
    url: String,
    placeholder: String,
    selected: String,
    timeSheetId: {
      type: String,
      default: 'null'
    }
  };

  static outlets = ['cascading-select']

  // Triggered when the Stimulus controller is connected to the DOM.
  connect() {
    this.initializeTomSelect();
    this.setSelected();
    // no url = this is the dependent select so no need to update self
    if (this.hasUrlValue) {
      this.element.addEventListener('change', () => {
        this.getCascadingList(this.select.activeOption.dataset.value, this.timeSheetIdValue)
      })
    }
  }

  getCascadingList(customer, time_sheet_id) {
    get(`/staff/customers/find-wards?customer=${customer}&time_sheet=${time_sheet_id}`, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    }).then(response => {
      if (response.ok) {
        // request.js handles this
      } else {
        console.log(response)
      }
    })
  }

  // Triggered when the Stimulus controller is removed from the DOM.
  disconnect() {
    this.destroyTomSelect();
  }

  // Initialize the TomSelect dropdown with the desired configurations.
  initializeTomSelect() {
    // Return early if no element is associated with the controller.
    if (!this.element) return;

    // Construct URL for fetching data which comes from the static value.
    // https://tom-select.js.org/examples/remote/
    const url = `${this.urlValue}.json`;

    // Fetch data for the dropdown.
    const fetchData = (search, callback) => {
      // prevent loading remotely when there is no url
      if (!this.hasUrlValue) return callback()

      fetch(url)
        .then(response => response.json())  // Convert response to JSON.
        .then(data => callback(data))       // Send data to TomSelect.
        .catch(() => callback());           // Handle any errors.
    };

    // Create a new TomSelect instance with the specified configuration.
    // see: https://tom-select.js.org/docs/
    // value, label, search, placeholder, etc can all be passed as static values instead of hard-coded.
    this.select = new TomSelect(this.element, {
      // plugins: ['remove_button'],
      valueField: 'name',
      labelField: 'name',
      searchField: ['name'],
      maxItems: 1,
      selectOnTab: true,
      placeholder: this.placeholderValue,
      closeAfterSelect: true,
      hidePlaceholder: true,
      copyClassesToDropdown: true,
      preload: true,
      create: true,
      createOnBlur: true,
      openOnFocus: true,
      highlight: true,
      sortField: { field: "name", direction: "asc" },
      load: fetchData
    });
  }

  setSelected() {
    if (! this.hasSelectedValue) return

    this.select.addOption({name: this.selectedValue})
    this.select.addItem(this.selectedValue)

    if (this.timeSheetIdValue === 'null') return
    this.getCascadingList(this.selectedValue, this.timeSheetIdValue)
  }

  // Cleanup: Destroy the TomSelect instance when the controller is disconnected.
  destroyTomSelect() {
    if (this.select) {
      this.select.destroy();
    }
  }
}